import React from 'react';
import { Helmet } from 'react-helmet';

import { API_ROOT, URL } from 'src/resources/constants/url';
import { BREADCRUM_NAME } from './data';

const BreadcrumRichSnippet = props => {
  const { url } = props;

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getItems = urlArr => {
    const result = [];
    result.push({
      '@type': 'ListItem',
      'position': 2,
      'name': BREADCRUM_NAME[urlArr[0]],
      'item': `${API_ROOT}${urlArr[0]}`,
    });
    if (urlArr.length === 1) return result;
    if (urlArr[0] === URL.HELP_AND_SUPPORT) {
      result.push({
        '@type': 'ListItem',
        'position': 3,
        'name': capitalizeFirstLetter(urlArr[1].slice(1)),
        'item': `${API_ROOT}${urlArr.join('')}`,
      });
      return result;
    }
    return result;
  };

  let itemListElement = [{
    '@type': 'ListItem',
    'position': 1,
    'name': 'MyConstant',
    'item': API_ROOT,
  }];
  if (typeof(url) === 'string') {
    itemListElement.push({
      '@type': 'ListItem',
      'position': 2,
      'name': BREADCRUM_NAME[url],
      'item': `${API_ROOT}${url}`,
    });
  } else if (Array.isArray(url)) {
    const items = getItems(url);
    itemListElement = itemListElement.concat(items);
  }
  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': itemListElement,
  };
  return (
    <Helmet key={url}>
      <script type="application/ld+json">
        {JSON.stringify(ldJson)}
      </script>
    </Helmet>
  );
};

export default BreadcrumRichSnippet;