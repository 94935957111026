import { URL } from 'src/resources/constants/url';

export const BREADCRUM_NAME = {
  [URL.USER_SIGN_UP]: 'Sign up',
  [URL.USER_SIGN_IN]: 'Login',
  [URL.USER_FORGET_PASSWORD]: 'Forget password',
  [URL.USER_FORGET_PASSWORD_FINISH]: 'Forget finish',
  [URL.PRO_LENDING]: 'Pro Lending',
  [URL.FLEX]: 'Deposit USD',
  [URL.DEPOSIT]: 'Deposit USD',
  [URL.DEPOSIT_FIAT]: 'Deposit USD',
  [URL.INVEST]: 'Lend USD',
  [URL.DEPOSIT_CRYPTO]: 'Deposit Crypto',
  [URL.FLEX_CRYPTO]: 'Lend Crypto',
  [URL.CRYPTO_LOAN]: 'Crypto-backed Loans',
  [URL.CRYPTO_SHORT]: 'Short Selling (Advanced)',
  [URL.CRYPTO_SHORT_TRIAL]: 'Trial Short Selling (Advanced)',
  [URL.SHORT_SIMPLE]: 'Short Selling (Simple)',
  [URL.SHORT_SIMPLE_TRIAL]: 'Trial Short Selling (Simple)',
  [URL.CRYPTO_FUTURE]: 'Futures',
  [URL.CRYPTO_FUTURE_TRIAL]: 'Trial Futures',
  [URL.ABOUT_US]: 'About Us',
  [URL.TESTIMONIALS]: 'Why people love us',
  [URL.BADGES]: 'Badges',
  [URL.AFFILIATES]: 'Affiliate Program',
  [URL.REFERRAL]: 'Share & Earn',
  [URL.FEE_AND_SERVICES_TIMES]: 'Fees and Service times',
  [URL.LIVE_CHAT]: 'Live chat',
  [URL.CALL_US]: 'Call us',
  [URL.WITHDRAW_FEE]: 'Fee schedule and collateral rules',
  [URL.MEMBERSHIP]: 'Membership',
  [URL.STATISTICS]: 'Statistics',
  [URL.GIFT_CARDS]: 'Gift cards',
  [URL.REGISTER_ACCREDITATION]: 'Register accreditation',
  [URL.AGREEMENTS]: 'Agreements',
  [URL.WITHDRAW]: 'Withdraw',
  [URL.COLLATERAL_BALANCE]: 'Coin Balance',
  [URL.TRANSFER_CRYPTO]: 'Transfer crypto',
  [URL.TRANSFER]: 'Transfer Fiat',
  [URL.HELP_AND_SUPPORT]: 'Help & Support',
  [URL.LOAN_ORIGINATORS]: 'Loan originator',
  [URL.SECONDARY_MARKET]: 'Secondary Market',
  [URL.DEBIT_CARD]: 'Debit Card',
  [URL.CRYPTO_SWAP]: 'Crypto Swap',
  [URL.CONSTANT_TOKEN]: 'MyConstant Token',
  // [URL.AIRDROP_WHEEL]: 'MCT Airdrop',
  [URL.CRYPTO_LEND_SIGN_UP]: 'Crypto Lend Sign up',
  [URL.REDEEM_MCT]: 'Redeem MCT Token',
  [URL.MCT_STAKING]: 'MCT Staking',
  [URL.BUY_MCT]: 'Buy MCT',
  [URL.MCT_MASCOT]: 'NFT Collection',
  [URL.MASCOT_NFT_DETAIL]: 'NFT Collection',
};